import type { GetStaticProps, NextPage } from "next";
import Head from "next/head";
import { GlobalProps, getGlobals } from "@src/lib/services/server/contentful";
import type {
  ProductNavigationQuery,
  ProductFooterQuery
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { SmartLink, ProductLayout, ProductContainer } from "@src/components";

export type Custom404Props = GlobalProps;

export const Custom404: NextPage<Custom404Props> = ({ navigation, footer }) => {
  return (
    <>
      <Head>
        <title>Page Not Found</title>
      </Head>
      <ProductLayout navigation={navigation} footer={footer}>
        <div className="bg-pr-yellow-200 py-8">
          <ProductContainer>
            <div className="grid gap-6 md:grid-cols-2">
              <div className="flex flex-col justify-center space-y-8 lg:space-y-12">
                <h1 className="type-pr-beta">Sorry, page not found</h1>
                <div className="space-y-6 md:space-y-10">
                  <div className="type-pr-zeta">
                    <p>
                      The page you are looking for cannot be found. Please go
                      back to your previous page or continue to our homepage.
                    </p>
                  </div>
                  <div>
                    <SmartLink
                      href="/"
                      className="pr-button pr-button-lg pr-button-red"
                    >
                      Back to Home
                    </SmartLink>
                  </div>
                </div>
              </div>

              <div className="hidden md:block">
                <svg
                  viewBox="0 0 688 548"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M555 548L418 548C418 470.125 479.334 407 555 407L555 548Z"
                    className="fill-[#C6E7D0]"
                  />
                  <path
                    d="M422.441 136.552L422.441 -5.75454e-06C495.152 -2.57628e-06 554.09 61.1338 554.09 136.553L422.441 136.552Z"
                    className="fill-pr-teal-100"
                  />
                  <path
                    d="M555.34 406.542L555.34 271.118C628.656 271.118 688.085 331.746 688.085 406.541L555.34 406.542Z"
                    className="fill-pr-dark-gray-500"
                  />
                  <path
                    d="M553.887 204.5C553.887 167.231 523.656 137 486.387 137C449.118 137 418.887 167.231 418.887 204.5L553.887 204.5Z"
                    className="fill-[#FEC342] opacity-[0.35]"
                  />
                  <path
                    d="M553.887 272C553.887 234.731 523.892 204.5 486.914 204.5C449.936 204.5 419.941 234.731 419.941 272L553.887 272Z"
                    className="fill-[#FEC342] opacity-[0.35]"
                  />
                  <path d="M0 137H141V409H0V137Z" className="fill-[#FFE7B8]" />
                  <path
                    d="M141 409H280V548H141V409Z"
                    className="fill-pr-teal-100"
                  />
                  <path
                    d="M280 339.5V270H141V339.5C141 377.874 172.115 409 210.474 409C248.885 409 280 377.874 280 339.5Z"
                    className="fill-[#84D7A0]"
                  />
                  <path
                    d="M349.5 413C387.874 413 419 443.23 419 480.498C419 517.766 387.874 547.996 349.5 547.996C311.127 547.996 280 517.766 280 480.498C280 443.23 311.127 413 349.5 413Z"
                    className="fill-pr-dark-gray-500"
                  />
                </svg>
              </div>
            </div>
          </ProductContainer>
        </div>
      </ProductLayout>
    </>
  );
};

export const getStaticProps: GetStaticProps<Custom404Props> = async context => {
  const { preview = false } = context;
  const props: Custom404Props = {};

  const globalsQuery = getGlobals(preview);

  Object.assign(props, await globalsQuery);

  return { props, revalidate: 60 };
};

export default Custom404;
